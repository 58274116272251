import styled from 'styled-components';
import { CSSVars } from '../../styles/styles.config';

export const StyledWidgetsHero = styled.section`
	color: ${CSSVars.white};
	overflow: hidden;
	padding: 0px;
	background: linear-gradient(180deg, #7d93ed 0%, #3047a6 100%);
	position: relative;

	.header-wrapper {
		aside {
			nav {
				a {
					color: ${CSSVars.gray900};
				}
			}
		}

		nav {
			a {
				color: ${CSSVars.white};
			}

			.hamburger {
				color: ${CSSVars.white};
			}

			.resources,
			.apps,
			.support,
			.solutions {
				a {
					color: ${CSSVars.gray900};
				}
			}
		}

		header {
			background: none !important;
			z-index: 1000;
			position: relative;

			@media screen and (min-width: ${CSSVars.breakpointMD}) {
				background: none !important;
			}
		}
	}

	footer {
		background: #0b0b30 !important;
		.top {
			border-bottom: 1px solid ${CSSVars.gray400} !important;
			.column {
				a {
					&.blue,
					.icon {
						color: ${CSSVars.primary300};
					}
				}
			}
		}
	}

	.hero-wrapper {
		position: relative;
		text-align: center;
		min-height: 100vh;

		padding: 20px 20px 0;
		@media screen and (min-width: ${CSSVars.breakpointLG}) {
			padding: 20px 40px 0;
		}

		.water {
			z-index: 10;
			height: 150px;
			left: 0;
			right: 0;
			bottom: 0px;
			background-color: #fff;
			position: absolute;
			background: linear-gradient(
				180deg,
				#3f54ad 7.22%,
				#273a8c 40.45%,
				#0a0a30 99.5%
			);
		}

		.mountains {
			position: absolute;
			bottom: 100px;
			z-index: 1;

			&.left {
				transform: scale(0.5);
				left: -200px;
				bottom: 10px;

				@media screen and (min-width: ${CSSVars.breakpointXL}) {
					left: -30px;
					transform: scale(1);
				}
			}
			&.right {
				transform: scale(0.5);
				right: -300px;
				bottom: 10px;

				@media screen and (min-width: ${CSSVars.breakpointXL}) {
					right: -80px;
					transform: scale(1);
				}
			}
		}

		.land {
			position: absolute;
			bottom: 0;
			z-index: 15;

			&.left {
				transform: scale(0.5);
				left: -200px;

				@media screen and (min-width: ${CSSVars.breakpointXL}) {
					left: -40px;
					transform: scale(1);
				}
			}
			&.right {
				transform: scale(0.5);
				right: -170px;

				@media screen and (min-width: ${CSSVars.breakpointXL}) {
					right: -60px;
					transform: scale(1);
				}
			}
		}
		.leaf {
			position: absolute;
			top: -290px;

			transform: scale(0.5);

			@media screen and (min-width: ${CSSVars.breakpointXL}) {
				transform: scale(1);
			}

			&.right {
				right: -250px;
			}
			&.left {
				left: -250px;
			}
		}

		.shinto {
			position: absolute;
			max-width: 100%;
			left: 50%;
			transform: translateX(-50%);
			bottom: 30px;
			z-index: 10;
		}

		.main-content {
			display: flex;
			flex-direction: column;
			align-items: center;
			position: relative;
			z-index: 50;

			.img-wrapper {
				position: relative;

				h2 {
					font-size: 400px;
					font-weight: 700;
					display: flex;
					align-items: center;
					gap: 20px;
					color: #223583;
					font-size: 130px;

					@media screen and (min-width: ${CSSVars.breakpointSM}) {
						font-size: 200px;
					}

					@media screen and (min-width: ${CSSVars.breakpointMD}) {
						font-size: 300px;
					}

					@media screen and (min-width: ${CSSVars.breakpointXL}) {
						font-size: 400px;
					}

					.moon {
						width: 100px;
						height: 100px;
						background-color: #fff;
						border-radius: 50%;
						box-shadow: 0px 0px 80px #ffffff;

						@media screen and (min-width: ${CSSVars.breakpointSM}) {
							width: 150px;
							height: 150px;
						}

						@media screen and (min-width: ${CSSVars.breakpointMD}) {
							width: 250px;
							height: 250px;
						}

						@media screen and (min-width: ${CSSVars.breakpointXL}) {
							width: 350px;
							height: 350px;
						}
					}
				}

				.clouds {
					position: absolute;
					z-index: -1;
					top: 50%;
					left: 50%;
					transform: translateX(-50%);
				}
			}

			h1 {
				letter-spacing: 0.33em;
				position: relative;
				z-index: 10;
				margin: 0 auto;
				font-size: ${CSSVars.fontSize2XL};
				font-weight: 600;
				max-width: 750px;
				margin-bottom: 20px;

				@media screen and (min-width: ${CSSVars.breakpointMD}) {
					font-size: ${CSSVars.fontSize4XL};
				}
			}
		}
	}
`;
