import Image from 'next/image';
import React from 'react';
import { Footer } from '../footer/footer.comp';
import { Header } from '../header/header.comp';
import { StyledWidgetsHero } from './notFound.styles';

export const NotFound = () => {
    return (
        <StyledWidgetsHero>
            <div className="header-wrapper">
                <Header is404 logoPath='/' />
            </div>
            <div className="hero-wrapper">
                <div className="main-content">
                    <div className="img-wrapper">
                        <h2>
                            4
                            <div className="moon"></div>
                            4
                        </h2>
                        <Image className='clouds' src={'/assets/404-clouds.webp'} alt='clouds' width={975} height={111} />
                    </div>
                    <h1>Page not found</h1>
                </div>
                <div className="water"></div>
                <Image className='shinto' src={'/assets/404-shinto.webp'} alt='shinto' width={233} height={276} />
                <Image className='leaf left' src={'/assets/404-leaf-left.webp'} alt='leaf' width={526} height={540} />
                <Image className='leaf right' src={'/assets/404-leaf-right.webp'} alt='leaf' width={477} height={681} />
                <Image className='land left' src={'/assets/404-land-left.webp'} alt='land' width={625} height={229} />
                <Image className='land right' src={'/assets/404-land-right.webp'} alt='land' width={456} height={209} />
                <Image className='mountains left' src={'/assets/404-mountains-left.webp'} alt='mountains' width={682} height={437} />
                <Image className='mountains right' src={'/assets/404-mountains-right.webp'} alt='mountains' width={820} height={371} />
            </div>
            <Footer isDark={true} />
        </StyledWidgetsHero>
    );
};
