import { Layout } from "../components/layout/layout.comp";
import { NotFound } from "../components/notFound/notFound.comp";

export default function NotFoundPage() {
    return (
        <Layout
            showFooter={false}
            showHeader={false}
            nofollow={true}
            noindex={true}
            title="Not found"
        >
            <NotFound />
        </Layout>
    );
}

